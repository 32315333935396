<template>
  <v-container class="bg-section mb-16" fluid>
    <div class="text-center">
      <h2 class="mb-6">
        Gestiona tu día a día de una forma más eficiente
      </h2>
      <p class="mx-16">La contabilidad de siempre, automática como nunca.</p>
    </div>
    <div class="bg-cards mt-12">
      <v-row class="justify-center align-center text-center pa-8">
        <v-col cols="12" :md="3" lg="2" class="pa-4 mx-4 mb-6 bg-card" v-for="element in elements" :key="element">
          <v-img
            :src="require(`@/assets/icons/icon-`+element.img+`.png`)"
            contain
            height="70"
            class="my-6"
          />
          <h5 class="mb-2"> {{ element.title }} </h5>
          <h6> {{ element.subtitle }}  </h6>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "DayToDay",

  data: () => ({
      elements : [
        {title: "Facturas y tickets", subtitle: "Contabiliza las facturas y tickets de tus clientes automáticamente.", img: "ft"},
        {title: "Movimientos bancarios", subtitle: "Concilia los movimientos bancarios de forman sencilla y en un instante.", img: "mb"},
        {title: "Contabilidad", subtitle: "Integra todo de forma automática e intuitiva en la contabilidad.", img: "cbd"},
        {title: "Modelos de impuestos", subtitle: "Genera los modelos de impuestos sin esfuerzo y siempre actualizados.", img: "mi"}
      ]
  }),
};
</script>

<style scoped>
.bg-cards {
  background: #fdfdfd;
}

.bg-card {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}
</style>
