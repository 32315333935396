<template>
  <v-container class="pa-0" fluid>
    <BannerConsultants />
    <Work />
    <DayToDay />
    <HelpClients />
    <CTA />
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import BannerConsultants from "../components/sections/BannerConsultants.vue";
import Work from '../components/sections/Work.vue'
import DayToDay from '../components/sections/DayToDay.vue'
import HelpClients from '../components/sections/HelpClients.vue'
import CTA from '../components/sections/CTA.vue'


export default {
  name: "Consultants",

  components: {
    BannerConsultants,
    Work,
    DayToDay,
    HelpClients,
    CTA
  },
};
</script>
