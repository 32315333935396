<template>
  <v-container class=" mb-16" fluid>
    <v-row>
      <v-col :md="6" class="bg-section">
          <v-img
          :src="require(`@/assets/consultants/man-with-features.png`)"
          height="460px"
          contain
        ></v-img>
      </v-col>
      <v-col :md="6" class="bg-card">
        <div class="ml-4 ml-sm-10 add-mg">
           <h2 class="mb-10 mt-16 white--text">
            Facilita el día a día de tus clientes con una solución completa
            </h2>
            <p class="white--text">
            Verás cómo Acist te puede ayudar a gestionar tu asesoría de forma más eficiente y rentable.
            </p> 
            <v-btn
            text
            block
            x-large
            class="btn-cta white--text mt-12 pr-16 mr-16"
          >
            Solicita una demo 
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CTA",

  data: () => ({}),
};
</script>

<style scoped>
.bg-card {
  background: #141E56;
    border-radius: 0px 280px 0px 0px;
    min-height: 400px;
}
.bg-section {
  background: #fdfdfd;
}
.btn-cta {
    background: #5A88FE;
    box-shadow: 0px 1px 18px rgba(255, 255, 255, 0.5);
    border-radius: 40px;
}
.add-mg {
    margin-right: 160px;
    margin-top: 90px;
}
@media (max-width: 550px) {
  .add-mg {
    margin-right: 40px;
    margin-top: 90px;
  }
}
</style>
