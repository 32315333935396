<template>
  <v-container class="bg-section mb-16" fluid>
      <v-row class="justify-center align-center mb-14">
          <v-col class="mt-2 mt-16-md-and-down px-10 mx-12-md-and-down" sm="12" md="6">
              <div class="mb-8 text-center">
                  <h1 class="mb-5 white--text">Ofrece el servicio que tus clientes necesitan</h1>
                  <p class="pb-8 mt-8 white--text">Acist es el primer software de Contabilidad Colaborativa que te permite conectar con tus clientes y automatizar tareas para ofrecerles un mejor asesoramiento en tiempo real.</p>
              </div>
          </v-col>
      </v-row>
      <v-row class="mb-10 justify-center align-center">
        <v-col cols="12" xs="10" sm="8" md="4" class="bg-card">
          <div class="mx-10 mb-16 text-center">
            <v-img
              :src="require('@/assets/icons/icon-work.png')"
              contain
              height="50"
              class="mt-10"
            />
            <h3 class="pt-6 mb-6">Trabaja desde donde estés</h3>
            <p class="pb-2 mt-8">Trabaja con tus clientes en tiempo real y elimina el papeleo.</p>
          </div>
        </v-col>
        <v-col cols="12" xs="10" sm="8" md="4" class="bg-card">
          <div class="mx-10 mb-16 text-center">
            <v-img
              :src="require('@/assets/icons/icon-calendar.png')"
              contain
              height="50"
              class="mt-10"
            />
            <h3 class="pt-6 mb-6">Automatiza eficientemente</h3>
            <p class="pb-2 mt-8">Reduce en un 80% el tiempo de gestión y elimina los picos de trabajo.</p>
          </div>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'BannerConsultants',

    components: {
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
.bg-section {
  background: url('../../assets/consultants/bg-abstract.png') no-repeat;
  top: 0;
  left: 0px;
  width:100%;
}
.bg-card {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
}
</style>
